<template>
  <div class="page-content resolution-detail-page">
    <div
      class="banner-area"
      :style="{ backgroundImage: `url(${banner})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }"
    >
      <div class="banner-slogan">
        <h2 class="title">{{ title }}</h2>
        <h4 class="desc">{{ desc }}</h4>
      </div>
    </div>
    <div class="detain-content" v-html="detailHtml" />
  </div>
</template>

<script>
import axios from 'axios'
import { description, keywords } from '../data/meta'

export default {
  name: 'ResolutionDetail',
  metaInfo: {
    title: '零方科技 | 解决方案',
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        name: 'keywords',
        content: keywords
      }
    ]
  },
  data() {
    return {
      banner: '',
      title: '',
      desc: '',
      detailHtml: ''
    }
  },
  methods: {
    getDetailData(id) {
      if (!id) return
      const self = this
      axios.get('/api/Open/GetProductDetail', { params: { pdtId: id } })
        .then(response => {
          response = response.data
          if (response.Code === '0') {
            self.detailHtml = response.Data.Detail
            self.banner = response.Data.Banner
            self.title = response.Data.ShortName
            self.desc = response.Data.Summary
            document.title = `零方科技 | ${self.title}`
          }
        })
    }
  },
  created() {
    this.getDetailData(this.$route.query.id)
  }
}
</script>

<style lang="less" scoped>
.resolution-detail-page {
  width: 100%;
  min-height: calc(100vh - 342px);
  .banner-area {
    width: 100%;
    height: 500px;
    background-color: #ccc;
    //background: url("../assets/img/px_banner_1.jpg") no-repeat;
    //background-size: 100% 100%;
    .banner-slogan {
      width: 1100px;
      margin: 0 auto;
      padding-top: 220px;
      color: #ffffff;
      .title {
        font-size: 46px;
        margin-bottom: 40px;
        text-align: center;
      }
      .desc {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
    }
  }
}
</style>
<style scoped>
.detain-content >>> p,
ul,
menu,
dir {
  max-width: 100%;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.detain-content >>> h3 {
  max-width: 100%;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.detain-content >>> h1 {
  max-width: 100%;
  font-weight: bold;
  font-size: 32px;
  margin: 21px 0;
}
.detain-content >>> h2 {
  max-width: 100%;
  font-weight: bold;
  font-size: 24px;
  margin: 19px 0;
}
.detain-content >>> h3 {
  max-width: 100%;
  font-weight: bold;
  font-size: 19px;
  margin: 18px 0;
}
.detain-content >>> h4 {
  max-width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin: 21px 0;
}
.detain-content >>> h5 {
  max-width: 100%;
  font-weight: bold;
  font-size: 13px;
  margin: 22px 0;
}
.detain-content >>> h6 {
  max-width: 100%;
  font-weight: bold;
  font-size: 11px;
  margin: 24px 0;
}
.detain-content >>> img {
  max-width: 100%;
}
.detain-content >>> option {
  padding: 0px 2px 1px;
}
.detain-content >>> input {
  padding: 1px;
}
.detain-content >>> fieldset {
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
}
.detain-content >>> textarea {
  padding: 2px;
}
.detain-content >>> legend {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}
.detain-content >>> a {
  color: inherit;
  /*text-decoration: underline;*/
}
.detain-content >>> ul {
  padding-left: 40px;
  padding-right: 40px;
  list-style: unset;
}
.detain-content >>> ol {
  list-style: decimal;
  padding-left: 40px;
  padding-right: 40px;
}
.detain-content >>> li {
  display: list-item;
  list-style: unset;
}
.detain-content >>> p {
  max-width: 100%;
  min-height: 1.5em;
}
</style>
