<template>
  <div class="resolution-description-page" v-loading="loadingDescription">
    <div class="description" v-html="descriptionHtml" v-if="descriptionHtml" />
    <div class="description--placeholder" v-else>暂无描述</div>
    <CommonLink
      :link="{ name: 'ResolutionDetail', query: { id: $route.query.id } }"
      v-if="descriptionHtml"
    />
  </div>
</template>

<script>
import axios from 'axios'
import CommonLink from '../components/CommonLink'
import { description, keywords } from '../data/meta'

export default {
  name: 'ResolutionDescription',
  metaInfo: {
    title: '零方科技 | 解决方案',
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        name: 'keywords',
        content: keywords
      }
    ]
  },
  components: {
    CommonLink
  },
  data() {
    return {
      descriptionHtml: '',
      loadingDescription: false
    }
  },
  watch: {
    $route() {
      if (this.$route.query.id) {
        this.getResolutionDescription(this.$route.query.id)
      }
    }
  },
  methods: {
    getResolutionDescription(id) {
      const self = this
      self.loadingDescription = true
      axios.get('/api/Open/GetProductDescription', { params: { pdtId: id } })
        .then(res => {
          self.loadingDescription = false
          res = res.data
          if (res.Code === '0') {
            self.descriptionHtml = res.Data
          }
        })
        .catch(e => {
          self.loadingDescription = false
          console.log(e)
        })
    }
  },
  created() {
    this.getResolutionDescription(this.$route.query.id)
  }
}
</script>

<style lang="less" scoped>
.resolution-description-page {
  width: 100%;
  height: 100%;
  .description--placeholder {
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 0.3);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/ .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}
</style>
