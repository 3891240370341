<template>
  <div class="page-content resolution-page">
    <div class="resolution-page-content">
      <div class="slogan-area">
        <div class="slogan-title">深耕零售行业，提供多场景解决方案</div>
        <div class="slogan-desc">深耕零售行业，零方高科技IT服务团队月均服务用户近2亿次，提供零售便利、会员体系、仓储物流、数智供应链、新零售电商、批发商贸、数据分析等多场景解决方案 ;</div>
      </div>
      <CommonTabView :data="resolutionData" :router-type="2" detail-router="ResolutionDescription"
                     v-if="resolutionData.length"/>
      <div class="page-content-placeholder" v-else>暂无解决方案信息</div>
    </div>
  </div>
</template>

<script>
import CommonTabView from '../components/CommonTabView'
import { description, keywords } from '../data/meta'

export default {
  name: 'Resolution',
  metaInfo: {
    title: '零方科技 | 解决方案',
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords + '解决方案,零售便利,会员体系,仓储物流,数智供应链,社区团购,外卖外送,社区医药,批发商贸,数据智能,金融支付' }
    ]
  },
  components: {
    CommonTabView
  },
  inject: ['resolution'],
  computed: {
    resolutionData() {
      return this.resolution()
    }
  }
}
</script>

<style lang="less" scoped>
.resolution-page {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  background: url("../assets/img/plan_bg.jpg") no-repeat fixed;
  background-size: cover;
  color: #FFFFFF;
  .resolution-page-content {
    width: 100%;
    padding: 80px 0;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    .slogan-area {
      width: 1200px;
      margin: 0 auto 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .slogan-title {
        font-size: 40px;
      }
      .slogan-desc {
        width: 645px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
      }
    }
    .page-content-placeholder {
      width: 100%;
      height: calc(100% - 208px);
      color: rgba(255, 255, 255, 0.3);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
